<template>
  <div class="error-page mt-8 pb-14 pb-md-0 pt-1 mt-md-0 pt-md-0 d-flex flex-column justify-center">
    <div class="d-flex flex-column align-center">
      <Error404 v-if="isPageNotFound" :width="media.isMobile ? 304 : 464" :height="media.isMobile ? 161 : 250" />
      <Error500 v-if="isServerError" :width="media.isMobile ? 304 : 464" :height="media.isMobile ? 161 : 250" />

      <div class="error-page__error-text my-4 my-md-6">
        <div class="text-h5 font-weight-medium text-center">
          <template v-if="hasTranslations">
            {{ $t(`error.reason_${code}`) }}
          </template>
          <template v-else>
            Internal server error
          </template>
        </div>

        <template v-if="isServerError">
          <i18n
            v-if="hasTranslations"
            :path="`error.possible_solution_${code}`"
            tag="div"
            class="mt-2 text-subtitle-2 text-md-h6 text--secondary text-center"
          >
            <template #link>
              <a class="error-page__link" target="_blank" href="https://argo.properties/">argo.properties</a>
            </template>
          </i18n>
          <div v-else>
            Please try again later
          </div>
        </template>
      </div>

      <div>
        <v-btn v-if="$options.historyLength" large color="primary" @click="goBack">
          <v-icon left>mdi-arrow-left</v-icon>

          <template v-if="hasTranslations">
            {{ $t('button.go_back') }}
          </template>

          <template v-else>
            go back
          </template>
        </v-btn>

        <v-btn v-else large color="primary" @click="refresh">
          <v-icon left>mdi-refresh</v-icon>

          <template v-if="hasTranslations">
            {{ $t('button.refresh') }}
          </template>

          <template v-else>
            refresh
          </template>
        </v-btn>
      </div>

      <div v-if="hasTranslations" class="error-page__contact_us mt-4 mt-md-8 text--secondary">
        {{ $t('error.contact_us') }}
        <a class="text--secondary" :href="`mailto:${email}`">{{ email }}</a>
      </div>
    </div>
  </div>
</template>

<script>
// Constants
import { ERROR_STATUSES } from '@/constants/http';

// Services
import companyService from '@/services/company';

// node_modules
import { mapState } from 'vuex';

// Icons
import Error404 from '@/assets/img/error_404.svg';
import Error500 from '@/assets/img/error_500.svg';

export default {
  name: 'ErrorPage',

  inject: ['media'],

  components: { Error404, Error500 },

  props: {
    code: { type: [String, Number], required: true },
  },

  data() {
    return {
      email: '',
    };
  },

  computed: {
    ...mapState({
      localeCount: state => state?.locale?.localeList?.instance?.length,
    }),

    hasTranslations() {
      const messages = this.$i18n?.messages?.[this.$i18n?.locale] || {};

      return this.localeCount > 0 && Object.keys(messages)?.length > 0;
    },

    isServerError() {
      return this.code === ERROR_STATUSES.INTERNAL_SERVER_ERROR;
    },

    isPageNotFound() {
      return this.code === ERROR_STATUSES.NOT_FOUND;
    },
  },

  beforeMount() {
    companyService.getCompany().then(({ email }) => {
      this.email = email;
    });
  },

  methods: {
    goBack() {
      this.$router.back();
    },

    refresh() {
      window.location.reload();
    },
  },

  historyLength: window.history.length,
};
</script>

<style lang="scss">
.error-page {
  max-width: 738px;
  height: 100%;

  margin-left: auto;
  margin-right: auto;

  &__logo {
    max-width: 121px;
  }

  &__link {
    color: rgba($--blue-color-20, 0.5) !important;
  }

  &__contact_us {
    font-size: 16px;
    line-height: 24px;
  }

  &__image {
    width: 464px;
    height: 250px;

    @media (max-width: map.get($--screens, 'sm')) {
      width: 304px;
      height: 161px;
    }
  }
}
</style>
